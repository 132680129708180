import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button, colors, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import SecureLS from 'secure-ls';
import { ExpandMore } from '@material-ui/icons';
import { isBlank } from '../../../../../../common/common';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: 200,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign:'left'
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  accordion: {
    '&:before': {
      display: 'none'
    },
    '&.Accordion-root:before': {
      display: 'none',
    },
    boxShadow: "none"
  }
}));

const secureLS = new SecureLS({encodingType: 'aes', encryptionSecret: process.env.AES_KEY});

const SidebarNav = props => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();

  return (
    <List
      {...rest}
      className={clsx(classes.root, className)}
    >
      {
        pages.map(page => page.securityKey === null || secureLS.get('cloud_license.accessibles').indexOf(page.securityKey) !== -1 ? (
          isBlank(page.children) ? (
            <ListItem
              className={classes.item}
              key={page.title}
            >
              <a href={page.href === window.location.pathname ? '#' : page.href}>
                <Button
                  activeclassname={classes.active}
                  className={classes.button}
                  style={window.location.pathname === page.href ? { borderLeft: '3px solid', backgroundColor: '#ddffff', borderColor: '#2196F3' } : null}
                >
                  <div className={classes.icon}>{page.icon}</div>
                  {page.title}
                </Button>
              </a>
            </ListItem>
          ) : (
            <React.Fragment key={page.title}>
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  // square
                  expandIcon={<ExpandMore />}
                  className={classes.accordion}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  transitionprops={{
                    timeout: 0
                  }}
                >
                  <Button
                    activeclassname={classes.active}
                    className={classes.button}
                  >
                    <div className={classes.icon}>{page.icon}</div>
                    {page.title}
                  </Button>
                </AccordionSummary>
                <AccordionDetails>
                  <List
                    {...rest}
                    className={clsx(classes.root, className)}
                  >
                    {
                      page.children.map((child, index) => {
                        return  child.securityKey === null || secureLS.get('cloud_license.accessibles').indexOf(child.securityKey) !== -1 ?(
                          <React.Fragment key={index}>
                            <a href={child.href === window.location.pathname ? '#' : child.href}>
                              <Button
                                activeclassname={classes.active}
                                className={classes.button}
                                style={window.location.pathname === child.href ? { borderLeft: '3px solid', backgroundColor: '#ddffff', borderColor: '#2196F3' } : null}
                              >
                                <div className={classes.icon}>{child.icon}</div>
                                {child.title}
                              </Button>
                            </a>
                          </React.Fragment>
                        ) : null
                      })
                    }
                  </List>
                </AccordionDetails>
              </Accordion>
            </React.Fragment>
          )
        ):null)
      }
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
