import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography, colors } from '@material-ui/core';
import SecureLS from 'secure-ls';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    margin: 10,
    width: 50,
    height: 50,
    color: '#fff',
    backgroundColor: colors.blue[600]
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const Profile = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const secureLS = new SecureLS({encodingType: 'aes', encryptionSecret: process.env.AES_KEY});

  const user = {
    name: secureLS.get('cloud_license.name'),
    initials: secureLS.get('cloud_license.initials'),
    bio: secureLS.get('cloud_license.email'),
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Avatar
        // alt="Person"
        className={classes.avatar}
        // src={user.avatar}
      >
        {user.initials}
      </Avatar>
      <Typography
        className={classes.name}
        variant="h5"
      >
        {user.name}
      </Typography>
      <Typography variant="body2">{user.bio}</Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
