import React, {lazy} from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout, AuthenticatedRouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

// import {
//   Dashboard as DashboardView,
//   ClientView as ClientView,
//   Settings as SettingsView,
//   Account as AccountView,
//   SignIn as SignInView,
//   ChangePassword as ChangePasswordView,
//   NotFound as NotFoundView,
//   Reports as ReportsView,
//   Users as UsersView
// } from './views';

// const MainLayout = lazy(() => import('./layouts/Main'))
// const MinimalLayout = lazy(() => import('./layouts/Minimal'))
const DashboardView = lazy(() => import('./views/Dashboard'))
const ClientView = lazy(() => import('./views/ClientView'))
const ClientForReview = lazy(() => import('./views/ClientForReview/ClientForReview'))
const SettingsView = lazy(() => import('./views/Settings'))
const SignInView = lazy(() => import('./views/SignIn'))
const ChangePasswordView = lazy(() => import('./views/ChangePassword'))
const NotFoundView = lazy(() => import('./views/NotFound'))
const MyLoginsView = lazy(() => import('./views/MyLogins'))
const MonthlyProcessView = lazy(() => import('./views/MonthlyProcess'))
const MonthlyProcessPerCompanyView = lazy(() => import('./views/MonthlyProcessPerCompany'))
const ClientOtherInfoView = lazy(() => import('./views/ClientOtherInfo'))
const CompaniesOtherInfoView = lazy(() => import('./views/CompaniesOtherInfo'))
const AuditTrailClientsView = lazy(() => import('./views/AuditTrailClients'))
const AuditTrailClientCompaniesView = lazy(() => import('./views/AuditTrailClientCompanies'))
const AuditTrailUsersView = lazy(() => import('./views/AuditTrailUsers'))
const AuditTrailLicensesView = lazy(() => import('./views/AuditTrailLicenses'))
const AuditTrailJPSLicensesView = lazy(() => import('./views/AuditTrailJPSLicenses'))
const AuditTrailESSLicensesView = lazy(() => import('./views/AuditTrailESSLicenses'))
const AuditTrailRemindersView = lazy(() => import('./views/AuditTrailReminders'))
const AuditTrailMessagesView = lazy(() => import('./views/AuditTrailMessages'))
const UsersView = lazy(() => import('./views/Users'))
const ForReviewClientsView = lazy(() => import('./views/ForReviewClientsView'))
const BlackListedClientView = lazy(() => import('./views/BlackListedClients/BlackListedClientView'))
const DeletedClientView = lazy(() => import('./views/DeletedClients/DeletedClientsView'))
const TaggingView = lazy(() => import('./views/Tagging/Tagging'))


const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/sign-in"
      />
      {/* <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      /> */}
      <AuthenticatedRouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      {/* <AuthenticatedRouteWithLayout
        component={ClientForReview}
        exact
        layout={MainLayout}
        path="/clients/for_review"
      /> */}
      <AuthenticatedRouteWithLayout
        component={BlackListedClientView}
        exact
        layout={MainLayout}
        path="/clients/blacklisted"
      />
      <AuthenticatedRouteWithLayout
        component={DeletedClientView}
        exact
        layout={MainLayout}
        path="/clients/deleted"
      />
      <AuthenticatedRouteWithLayout
        component={ClientForReview}
        exact
        layout={MainLayout}
        path="/clients/for_review"
      />
      <AuthenticatedRouteWithLayout
        component={ClientView}
        exact
        layout={MainLayout}
        path="/clients"
      />
      <AuthenticatedRouteWithLayout
        component={ForReviewClientsView}
        exact
        layout={MainLayout}
        path="/staging"
      />
      <AuthenticatedRouteWithLayout
        component={MyLoginsView}
        exact
        layout={MainLayout}
        path="/my-logins"
      />
      <AuthenticatedRouteWithLayout
        component={MonthlyProcessView}
        exact
        layout={MainLayout}
        path="/monthly-processes"
      />
      <AuthenticatedRouteWithLayout
        component={MonthlyProcessPerCompanyView}
        exact
        layout={MainLayout}
        path="/monthly-processes-per-company"
      />
      <AuthenticatedRouteWithLayout
        component={ClientOtherInfoView}
        exact
        layout={MainLayout}
        path="/client-other-info"
      />
      <AuthenticatedRouteWithLayout
        component={CompaniesOtherInfoView}
        exact
        layout={MainLayout}
        path="/companies-other-info"
      />
      <AuthenticatedRouteWithLayout
        component={AuditTrailClientsView}
        exact
        layout={MainLayout}
        path="/audit-trail-clients"
      />
      <AuthenticatedRouteWithLayout
        component={AuditTrailClientCompaniesView}
        exact
        layout={MainLayout}
        path="/audit-trail-client-companies"
      />
      <AuthenticatedRouteWithLayout
        component={AuditTrailUsersView}
        exact
        layout={MainLayout}
        path="/audit-trail-users"
      />
      <AuthenticatedRouteWithLayout
        component={AuditTrailLicensesView}
        exact
        layout={MainLayout}
        path="/audit-trail-licenses"
      />
      <AuthenticatedRouteWithLayout
        component={AuditTrailJPSLicensesView}
        exact
        layout={MainLayout}
        path="/audit-trail-jps-licenses"
      />
      <AuthenticatedRouteWithLayout
        component={AuditTrailESSLicensesView}
        exact
        layout={MainLayout}
        path="/audit-trail-ess-licenses"
      />
      <AuthenticatedRouteWithLayout
        component={AuditTrailRemindersView}
        exact
        layout={MainLayout}
        path="/audit-trail-reminders"
      />
      <AuthenticatedRouteWithLayout
        component={AuditTrailMessagesView}
        exact
        layout={MainLayout}
        path="/audit-trail-messages"
      />
      <AuthenticatedRouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <AuthenticatedRouteWithLayout
        component={UsersView}
        exact
        layout={MainLayout}
        path="/users"
      />
      <AuthenticatedRouteWithLayout
        component={TaggingView}
        exact
        layout={MainLayout}
        path="/clients/tagging"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={ChangePasswordView}
        exact
        layout={MinimalLayout}
        path="/change-password"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
