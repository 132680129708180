import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import {
  TrackChanges, Event, VpnKey,
  BusinessCenter, Settings, ExitToApp,
  Assessment, Assignment, Person,
  RateReview,
  Delete,
  Cancel,
} from '@material-ui/icons';
import LocalOfferSharpIcon from '@material-ui/icons/LocalOfferSharp';

import { Profile, SidebarNav } from './components';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 300,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: 'Dashboard',
      href: '/dashboard',
      securityKey: null,
      icon: <Assessment />
    },
    {
      title: 'Clients',
      securityKey: 'ClientAccounts',
      href: '/clients',
      icon: <BusinessCenter />,
    },
    {
      title: 'For Review',
      href: '/clients/for_review',
      icon: <RateReview />,
      securityKey: 'ForReviewClients'
    },
    {
      title: 'Deleted',
      href: '/clients/deleted',
      icon: <Delete />,
      securityKey: 'DeletedClients'
    },
    {
      title: 'Blacklisted',
      href: '/clients/blacklisted',
      icon: <Cancel />,
      securityKey: 'BlackListedClients'
    },
    {
      title: 'Tagging',
      href: '/clients/tagging',
      securityKey: null,
      icon: <LocalOfferSharpIcon />
    },
  // {
  //   title: 'Staging',
  //   href: '/staging',
  //   securityKey: 'ClientAccounts',
    //   icon: <BusinessCenter />
    // },
    {
      title: 'User Accounts',
      href: '/users',
      securityKey: 'UserAccounts',
      icon: <Person />
    },
    {
      title: 'Reports',
      securityKey: 'Reports',
      icon: <Assignment />,
      children: [
        {
          title: 'My Logins',
          href: '/my-logins',
          icon: <VpnKey />,
          securityKey: 'Reports',
        },
        {
          title: 'Client Other Info',
          href: '/client-other-info',
          icon: <Assessment />,
          securityKey: 'Reports',
        },
        {
          title: 'Companies Other Info',
          href: '/companies-other-info',
          icon: <Assessment />,
          securityKey: 'Reports',
        },
        {
          title: 'Clients Audit Trail',
          href: '/audit-trail-clients',
          icon: <TrackChanges />,
          securityKey: 'Reports',
        },
        {
          title: 'Client Companies Audit Trail',
          href: '/audit-trail-client-companies',
          icon: <TrackChanges />,
          securityKey: 'Reports',
        },
        {
          title: 'Licenses Audit Trail',
          href: '/audit-trail-licenses',
          icon: <TrackChanges />,
          securityKey: 'Reports',
        },
        {
          title: 'JPS Licenses Audit Trail',
          href: '/audit-trail-jps-licenses',
          icon: <TrackChanges />,
          securityKey: 'Reports',
        },
        {
          title: 'ESS Licenses Audit Trail',
          href: '/audit-trail-ess-licenses',
          icon: <TrackChanges />,
          securityKey: 'Reports',
        },
        {
          title: 'Reminders Audit Trail',
          href: '/audit-trail-reminders',
          icon: <TrackChanges />,
          securityKey: 'Reports',
        },
        {
          title: 'Messages Audit Trail',
          href: '/audit-trail-messages',
          icon: <TrackChanges />,
          securityKey: 'Reports',
        },
        {
          title: 'Users Audit Trail',
          href: '/audit-trail-users',
          icon: <TrackChanges />,
          securityKey: 'Reports',
        },
        {
          title: 'Monthly Processes (per Client)',
          href: '/monthly-processes',
          icon: <Event />,
          securityKey: 'Reports',
        },
        {
          title: 'Monthly Processes (per Company)',
          href: '/monthly-processes-per-company',
          icon: <Event />,
          securityKey: 'Reports',
        },
      ]
    },
    {
      title: 'Settings',
      href: '/settings',
      securityKey: 'Settings',
      icon: <Settings />
    },
    {
      title: 'Sign-out',
      href: '/sign-in',
      securityKey: null,
      icon: <ExitToApp />
    },
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
