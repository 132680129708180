import React, {Suspense} from 'react';
import { Route, Redirect } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core'
import PropTypes from 'prop-types';
import axios from 'axios';
import SecureLS from 'secure-ls';
import dotenv from 'dotenv';
dotenv.config();

const secureLS = new SecureLS({encodingType: 'aes', encryptionSecret: process.env.AES_KEY});

const AuthenticatedRouteWithLayout = props => {
    const { layout: Layout, component: Component,  ...rest } = props;
    const checkSession = async() => {
        let session = await axios.get('/backend/check_session')
        if(Object.keys(session.data).length === 0)
        {
            window.location.replace('/sign-in')
        }
        secureLS.set('cloud_license.accessibles', session.data.accessibles)
    }
    return (
        <Route
            {...rest}
            render={(matchProps) => {
                    if(secureLS.get('cloud_license.user_id') !== '' ) {
                        checkSession();
                    }
                    return(
                        secureLS.get('cloud_license.user_id') !== '' ?
                        (
                            <Layout>
                                <Suspense fallback={<LinearProgress/>}>
                                    <Component {...matchProps} />
                                </Suspense>
                            </Layout>
                        )
                        : <Redirect to='/sign-in' />
                    )
                }
            }
        />
    );
};

AuthenticatedRouteWithLayout.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string
};

export default AuthenticatedRouteWithLayout;
